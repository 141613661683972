import React from "react"

import Layout from "../layouts/index"
import SEO from "../components/seo"
import Background from "../components/background"
import HomepageText from "../components/homepageText"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Background opacity="full" />
      <HomepageText />
    </Layout>
  )
}

export default IndexPage
