import React, { useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { useMediaQuery } from "react-responsive"

import { TimelineMax, gsap } from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { color } from "../utils"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

const OuterContainer = styled.div`
  position: relative;
  width: 100%;
  height: 102vh;

  .trigger {
    position: absolute;
    width: 1px;
    height: 80vh;
    visibility: hidden;
  }

  @media screen and (min-width: 768px) and (min-height: 430px) {
    height: 3000px;
    .trigger {
      height: 2000px;
    }
  }
`

const Container = styled.article`
  position: absolute;
  margin: 0 10px 20px 10px;
  padding: 0;

  display: grid;
  grid-gap: 7px;
  grid-template-columns: 1fr 90px;
  /* grid-template-rows: 2.5rem auto repeat(6, 2.5rem) 4rem 2.5rem; */
  grid-template-rows: auto;

  color: #01624c;
  font-size: 1rem;
  line-height: 2rem;

  .grid-cell {
    padding: 8px;
    background-color: #ffffff91;
  }

  .two {
    grid-column: 1 / 3;
    grid-row: 9 / 10;
    font-size: 2.8rem;
    line-height: 0.7;
    padding: 0 0 0 8px;
  }
  .two-aside {
    grid-column: 1 / 3;
    grid-row: 10 / 11;
    font-size: 1rem;
  }
  .three {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }
  .four {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
  }
  .five {
    grid-column: 1 / 3;
    grid-row: 5 / 6;
  }
  .six {
    grid-column: 1 / 3;
    grid-row: 6 / 7;
  }
  .seven {
    grid-column: 1 / 3;
    grid-row: 7 / 8;
  }
  .eight {
    grid-column: 1 / 3;
    grid-row: 8 / 9;
  }

  .web {
    grid-column: 1 / 3;
    grid-row: 1 / 2;

    text-align: center;
  }
  .name {
    grid-column: 2/3;
    grid-row: 2/3;
    max-height: max-content;

    white-space: nowrap;
    writing-mode: vertical-lr;
    text-align: right;

    h1 {
      font-size: 2rem;
      font-family: "MuseoModerno", cursive;
      color: ${color.primaryColor};
    }
  }
  .me {
    font-size: 1rem;
    color: #01624c;
  }

  animation: fade 1.2s ease-in-out forwards;

  @keyframes fade {
    to {
      opacity: 1;
    }
  }

  @media screen and (min-width: 768px) and (orientation: portrait) {
    position: fixed;
    top: 5vh;
    bottom: 10vh;
    left: 5vw;
    right: 5vw;
    margin: 10%;
    padding: 0;
    max-height: 100vh;

    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 90px;
    grid-template-rows: 2.5rem auto repeat(6, 2.5rem) 4rem 2.5rem;

    .name {
      height: fit-content;
    }
  }

  @media screen and (max-height: 430px) and (orientation: landscape) {
    .name {
      grid-column: 1/3;
      writing-mode: horizontal-tb;
      display: flex;
      flex-flow: column-reverse;
    }

    .web {
      text-align: left;
    }
  }

  @media screen and (max-height: 430px) and (min-width: 768px) {
    grid-gap: 7px;
    grid-template-columns: 1fr 90px;
    grid-template-rows: auto;
  }

  @media screen and (min-width: 768px) and (orientation: landscape) and (min-height: 431px) {
    position: fixed;
    top: 5vh;
    bottom: 5vh;
    left: 10vw;
    right: 10vw;
    margin: 10%;
    padding: 0;
    max-height: 60vh;
    min-height: 60vh;

    grid-gap: 10px;
    grid-template-columns: 2fr 1fr 90px;
    grid-template-rows: repeat(9, 1fr);

    //transform: rotate(-20deg);
    color: #01624c;
    font-size: 1.2rem;

    .grid-cell {
      padding: 8px;
      //border-bottom: 5px solid #32513e;
      background-color: #ffffff91;
    }

    .two {
      grid-column: 1 / 2;
      grid-row: 8 / 9;
      font-size: 4rem;

      margin: 0 -80px 0 0;
      padding: 0px 8px;
    }
    .two-aside {
      grid-column: 1 / 2;
      grid-row: 9 / 10;
      font-size: rem;
    }
    .three {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
    .four {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }
    .five {
      grid-column: 1 / 2;
      grid-row: 4 / 5;
    }
    .six {
      grid-column: 1 / 2;
      grid-row: 5 / 6;
    }
    .seven {
      grid-column: 1 / 2;
      grid-row: 6 / 7;
    }
    .eight {
      grid-column: 1 / 2;
      grid-row: 7 / 8;
    }

    .web {
      grid-column: 2 / 4;
      grid-row: 1 / 2;

      text-align: right;
    }
    .name {
      grid-column: 3/4;
      grid-row: 2/10;
      max-height: 100%;

      writing-mode: vertical-lr;

      h1 {
        font-size: 3rem;
        text-align: left;
      }
    }
    .me {
      font-size: 1rem;
      padding-bottom: 80px;
    }
  }

  .like {
    color: ${color.primaryColor};
  }

  .likes,
  .like,
  .two,
  .two-aside,
  .name,
  .web {
    visibility: hidden;
  }

  .two {
    opacity: 0;
  }
`

const HomepageText = () => {
  const refContainer = useRef(null)
  const isDesktopOrLaptop = useMediaQuery({
    minDeviceWidth: 768,
    minDeviceHeight: 430,
  })

  useEffect(() => {
    const elements = refContainer.current

    const container = elements.querySelector(".container")
    const trigger = elements.querySelector(".trigger")
    const nameTitle = elements.querySelector(".name")
    const webTitle = elements.querySelector(".web")
    const likes = elements.querySelectorAll(".likes")
    const likeTitle = elements.querySelector(".like")
    const bigFonts = elements.querySelector(".two")
    const typography = elements.querySelector(".two-aside")

    const tl = new TimelineMax()

    tl.set(
      [
        container,
        trigger,
        nameTitle,
        webTitle,
        likes,
        likeTitle,
        bigFonts,
        typography,
      ],
      {
        visibility: "visible",
      }
    )
  }, [])

  useEffect(() => {
    const elements = refContainer.current

    const nameTitle = elements.querySelector(".name")
    const webTitle = elements.querySelector(".web")

    function titlesF() {
      const tl = new TimelineMax({ delay: 0.5 })

      tl.fromTo(
        webTitle,
        { opacity: 0 },
        { opacity: 1, duration: 2, ease: "linear" },
        0
      ).fromTo(
        nameTitle,
        {
          opacity: 0,
        },
        { opacity: 1, duration: 3, ease: "linear" },
        "-=1"
      )

      return tl
    }

    const master = new TimelineMax({
      repeat: 0,
    })
    master.add(titlesF())
  }, [])

  /* likes animation if on Desktop */
  useEffect(() => {
    const elements = refContainer.current

    const trigger = elements.querySelector(".trigger")
    const likes = elements.querySelectorAll(".likes")
    const likeTitle = elements.querySelector(".like")

    if (isDesktopOrLaptop) {
      function likesF() {
        const tl = new TimelineMax()

        tl.fromTo(
          likeTitle,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 2,
            ease: "power1",
          }
        ).fromTo(
          likes,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 1.5,
            ease: "power1",
            stagger: 1,
          },
          "-=1"
        )

        return tl
      }

      const master = new TimelineMax({
        scrollTrigger: {
          trigger: trigger,
          start: "top top",
          end: "1500 top",
          scrub: 1,
        },
      })
      master.add(likesF())
    }
  }, [isDesktopOrLaptop])

  /* big fonts animation if on Desktop */
  useEffect(() => {
    const elements = refContainer.current

    const trigger = elements.querySelector(".trigger")
    const bigFonts = elements.querySelector(".two")
    const typography = elements.querySelector(".two-aside")
    const container = elements.querySelector(".container")

    if (isDesktopOrLaptop) {
      function bigfontsF() {
        const tl = new TimelineMax()

        tl.fromTo(
          bigFonts,
          { opacity: 0, y: "-120vh" },
          { opacity: 1, y: 0, duration: 2, ease: "bounce" },
          0
        )
          .fromTo(
            container,
            {
              rotation: 0,
              y: 0,
            },
            {
              rotation: -20,
              y: -20,
              duration: 1.5,
              ease: "bounce",
            },
            "-=1.4"
          )
          .fromTo(
            typography,
            {
              opacity: 0,
            },
            { opacity: 1, duration: 1.5, ease: "power1" }
          )

        return tl
      }

      const master = new TimelineMax({
        scrollTrigger: {
          trigger: trigger,
          start: "1500 top",
          end: "+=500",
          toggleActions: "play play reverse reverse",
        },
      })

      master.add(bigfontsF())
    }
  }, [isDesktopOrLaptop])

  /* likes and big fonts animation if on Mobile */
  useEffect(() => {
    const elements = refContainer.current

    const trigger = elements.querySelector(".trigger")
    const likes = elements.querySelectorAll(".likes")
    const likeTitle = elements.querySelector(".like")
    const bigFonts = elements.querySelector(".two")
    const typography = elements.querySelector(".two-aside")

    if (!isDesktopOrLaptop) {
      const tl = new TimelineMax({
        scrollTrigger: {
          trigger: trigger,
          start: "top top",
          /* toggleActions: "play play reverse reverse", */
        },
      })

      tl.fromTo(
        likeTitle,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 2,
          ease: "power1",
        }
      )
        .fromTo(
          likes,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 1,
            ease: "power1",
            stagger: 0.7,
          },
          "-=1"
        )
        .fromTo(
          bigFonts,
          { opacity: 0, y: "-140vh" },
          { opacity: 1, y: 0, duration: 2, ease: "bounce" },
          "-=1"
        )
        .fromTo(
          typography,
          {
            opacity: 0,
          },
          { opacity: 1, duration: 1.5, ease: "power1" }
        )
    }
  }, [isDesktopOrLaptop])

  return (
    <OuterContainer className="scrollContainer" ref={refContainer}>
      <div className="trigger"></div>
      <Container className="container">
        <div className="three grid-cell like">around here I LIKE:</div>
        <div className="four grid-cell likes">quirky things</div>
        <div className="five grid-cell likes">details</div>
        <div className="six grid-cell likes">
          some motion (but not mine... hi hi)
        </div>
        <div className="seven grid-cell likes">building stuff</div>
        <div className="eight grid-cell likes">learning stuff</div>
        <div className="two grid-cell">big fonts and I cannot lie</div>
        <div className="two-aside grid-cell">
          actually I just like typography :-)
        </div>

        <div className="web grid-cell">
          <h1>the web dev world</h1>
        </div>
        <div className="name grid-cell">
          <span className="me">(that's me!)</span>
          <h1>of isabel Moreira</h1>
        </div>
      </Container>
    </OuterContainer>
  )
}

export default HomepageText
